.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-size: 14px;
  background-color: #fff;
  margin-top: 47px;
  a {
    color: #262626;
  }
}
