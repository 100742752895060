@import "assets/styles/mixins.scss";

.bell-icon {
  font-size: 1.6rem;
}

.dropdown {
  position: relative;
  cursor: pointer;
  @include transition-slow;
  &:hover {
    color: $blue;

    &::after,
    .icon {
      color: $gray-5;
    }
  }
}

.icon {
  color: $gray-4;
  position: relative;
  bottom: -0.14rem;
  @include transition-slow;
}

.icon-indicator {
  color: $red;
  position: relative;
  font-size: 12px;
  left: 2px;
  @include transition-slow;
}
